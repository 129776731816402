<template>
  <div>
    <!-- Modal  start-->
    <CModal
      :show.sync="showModal"
      :close-on-backdrop="false"
      title="Modal title 2"
      size="xl"
      color="primary"
    >
      <template #header>
        <h6 class="modal-title">Idea</h6>
        <CButtonClose
          @click="ChangeModal()"
          class="text-white"
          v-if="viewer === false"
        />
      </template>

      <div>
        <CRow>
          <CCol>
            <h5 class="subhead-modal mb-3">Create Detail</h5>
          </CCol>
          <CCol>
            <div class="subhead-modal-action">
              <CLink
                class="card-header-action btn-minimize"
                @click="formCollapsed = !formCollapsed"
              >
                <CIcon name="cil-chevron-bottom" v-if="formCollapsed" />
                <CIcon name="cil-chevron-top" v-else />
              </CLink>
            </div>
          </CCol>
        </CRow>

        <CCollapse :show="formCollapsed">
          <CRow>
            <CCol md="6">
              <CInput
                label="Creator"
                horizontal
                readonly="readonly"
                v-model="ideaModel.IdeaCreator"
              />
              <!-- <input type="hidden" v-model="createUser"> -->
            </CCol>
            <CCol md="6">
              <CInput
                label="Create Date"
                horizontal
                readonly="readonly"
                v-model="ideaModel.IdeaCreateDate"
              />
            </CCol>
          </CRow>

          <CRow>
            <CCol md="6">
              <CInput
                label="E-mail"
                horizontal
                readonly="readonly"
                v-model="ideaModel.IdeaCreatorEmail"
              />
            </CCol>
            <CCol md="6">
              <CInput
                label="Business Unit"
                horizontal
                readonly="readonly"
                v-model="ideaModel.IdeaCreatorBU"
              />
            </CCol>
          </CRow>
        </CCollapse>
        <hr />
      </div>

      <div>
        <CRow class="mb-3">
          <CCol>
            <CInputCheckbox
              style="color: #cf1451"
              v-c-tooltip="{
                content:
                  'Check this box if this idea generated from ideation event.',
                placement: 'top',
              }"
              :label="'Ideation Event'"
              :checked="ideaModel.IdeaEventFlag"
              @click="EventFlagClick($event)"
            ></CInputCheckbox>
          </CCol>
        </CRow>

        <div v-if="ideaModel.IdeaEventFlag">
          <CRow>
            <CCol>
              <h5 class="subhead-modal mb-3">Ideation Event Detail</h5>
            </CCol>
          </CRow>
          <CRow>
            <CCol class="form-group form-row">
              <div class="col-md-3">
                <label class="">Event Date</label>
              </div>
              <div class="col-md-9 pr-0">
                <DatePicker
                  class="custom-input-date"
                  v-model="ideaModel.IdeaEventDate"
                  :first-day-of-week="2"
                  :masks="{ input: ['YYYY/MM/DD'], data: ['YYYY/MM/DD'] }"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <input
                      class="bg-white border px-2 py-1 rounded"
                      :value="inputValue"
                      v-on="inputEvents"
                      placeholder="YYYY/MM/DD"
                      readonly
                      :disabled="disabled"
                    />
                  </template>
                </DatePicker>
              </div>
            </CCol>
            <CCol class="form-group form-row">
              <div class="col-md-3">
                <label class="">Group</label>
              </div>
              <div class="col-md-9 pr-0">
                <v-select
                  v-model="ideaModel.IdeaGroup"
                  :value.sync="ideaModel.IdeaGroup"
                  :options="groupOptions"
                  placeholder="-- Select --"
                  :disabled="disabled"
                />
              </div>
            </CCol>
          </CRow>
        </div>
        <hr />
      </div>

      <CRow>
        <h5 class="subhead-modal pl-3">Market / Customer</h5>
      </CRow>

      <CRow>
        <CCol md="6" class="form-group form-row">
          <div class="col-md-3">
            <label class="">Industry <span class="error">*</span></label>
          </div>
          <div class="col-md-9 pr-0">
            <v-select
              ref="RefIdeaVSelect"
              v-model="ideaModel.IdeaIndustry"
              :value.sync="ideaModel.IdeaIndustry"
              :options="industryOptions"
              placeholder="-- Select --"
              @input="IndustryOnChange($event)"
              :disabled="disabled"
            />
          </div>
        </CCol>
        <CCol class="form-group form-row">
          <div class="col-md-3">
            <label class="">Segment</label>
          </div>
          <div class="col-md-9 pr-0">
            <v-select
              v-model="ideaModel.IdeaSegment"
              :value.sync="ideaModel.IdeaSegment"
              :options="segmentOptions"
              placeholder="-- Select --"
              @input="SegmentOnChange($event)"
              :disabled="disabled"
            />
          </div>
        </CCol>
      </CRow>
      <CRow>
        <CCol md="6" class="form-group form-row">
          <div class="col-md-3">
            <label class="">Sub Segment</label>
          </div>
          <div class="col-md-9 pr-0">
            <v-select
              v-model="ideaModel.IdeaSubSegment"
              :value.sync="ideaModel.IdeaSubSegment"
              :options="subSegmentOptions"
              placeholder="-- Select --"
              :disabled="disabled"
            />
          </div>
        </CCol>
      </CRow>
      <hr />
      <CRow>
        <h5 class="subhead-modal pl-3">Idea Generation</h5>
      </CRow>

      <CRow>
        <h5 class="subhead-modal pl-3 mb-3">Link from which Insights?</h5>
      </CRow>

      <CRow>
        <CCol md="6 form-group form-row">
          <div class="col-md-3">
            <label class="">Insight No.</label>
          </div>
          <div class="col-md-1">
            <div class="input-group">
              <div class="input-group-prepend">
                <CButton
                  color="search"
                  size="sm"
                  @click="searchInsight()"
                  v-if="viewmode !== 'view'"
                >
                  <CIcon name="cil-magnifying-glass" />Search
                </CButton>
              </div>
            </div>
          </div>
        </CCol>
      </CRow>

      <CRow>
        <CCol md="12">
          <CDataTable
            :items="InsightLinks"
            :fields="fieldsSelected"
            sorter
          ></CDataTable>
        </CCol>
      </CRow>
      <hr />

      <CRow>
        <h5 class="subhead-modal pl-3 mb-3">Idea Detail</h5>
      </CRow>

      <CRow>
        <CCol md="6">
          <CInput
            label="Idea No."
            horizontal
            v-model="ideaModel.IdeaNo"
            readonly
          />
        </CCol>
        <CCol md="6">
          <CInput
            label="Status"
            horizontal
            v-model="ideaModel.IdeaStatus"
            readonly
          />
        </CCol>
      </CRow>

      <CRow>
        <CCol md="6">
          <CTextarea
            rows="3"
            label="Idea"
            horizontal
            v-model="ideaModel.IdeaDetail"
            placeholder="What is your idea ?"
            required
            was-validated
            ref="IdeaDetail"
          />
        </CCol>
        <CCol md="6">
          <CTextarea
            rows="3"
            label="To solve"
            horizontal
            v-model="ideaModel.IdeaToSolve"
            placeholder="What is problem to be solved ?"
            required
            was-validated
            ref="IdeaToSolve"
          />
        </CCol>
      </CRow>

      <CRow>
        <CCol md="6">
          <CTextarea
            rows="3"
            label="Whom"
            horizontal
            v-model="ideaModel.IdeaWhom"
            placeholder="Who want this problem solved ?"
            required
            was-validated
            ref="IdeaWhom"
          />
        </CCol>
        <CCol md="6">
          <CInputFile
            multiple
            custom
            label="Attach files"
            horizontal
            :description="'(*Only .pdf, .doc, .xls, .ppt, .jpg, .png maximum size 5 MB)'"
            :ref="'inputfile'"
            v-on:change="fileChange($event)"
          />

          <div
            class="cpl-10"
            v-for="attachment in attachments"
            :key="attachment.AttachmentName"
          >
            <div v-if="!attachment.IsDelete">
              <CLink
                class="text-primary"
                @click="OpenFile(attachment.AttachmentName, true)"
              >
                {{ attachment.AttachmentName.split("/")[1] }}
              </CLink>
              <CButton
                color="danger"
                size="sm"
                @click="ConfirmDeleteFile(attachment)"
                v-if="viewmode !== 'view'"
              >
                Delete
              </CButton>
            </div>
          </div>

          <div class="cpl-10" v-for="(file, key) in files" :key="file.name">
            <CLink class="text-primary" @click="OpenFile(file)">
              {{ file.name }}
            </CLink>
            <CButton
              color="danger"
              size="sm"
              @click="ConfirmDeleteFileBeforeSave(key)"
            >
              Delete
            </CButton>
          </div>
        </CCol>
      </CRow>
      <CRow>
        <CCol
          md="6"
          class="form-group form-row"
          v-c-tooltip="{
            content: 'BU ที่เกี่ยวข้องกับ Idea นี้',
            placement: 'top',
          }"
        >
          <div class="col-md-3">
            <label class=""
              >Related business unit<span class="error">*</span></label
            >
          </div>
          <div class="col-md-9 pr-0">
            <v-select
              ref="RefVSelectBU"
              v-model="ideaModel.BU"
              :value.sync="ideaModel.BU"
              :options="buOptions"
              placeholder="-- Select --"
              :disabled="disabledRelateBu"
            />
          </div>
        </CCol>
      </CRow>

      <template #footer>
        <p ref="PwarningCanNotApprove" class="text-danger">
          Waiting for Insight Approve
        </p>
        <CButton
          ref="AppBtnRef"
          style="display: none"
          @click="K2Action('Approve')"
          color="success"
        >
          Approve
        </CButton>
        <CButton
          ref="RejectBtnRef"
          @click="K2Action('Reject')"
          style="background-color: #f7cc54; display: none"
        >
          Reject
        </CButton>
        <!-- <CButton
          @click="ChangeModal()"
          color="dark"
          v-if="
            userRole === 'Admin' && viewmode !== 'create' && viewmode !== 'view'
          "
          >Terminate</CButton
        > -->
        <CButton
          @click="K2Action('Submit')"
          color="submit-custom"
          ref="SubmitBtnRef"
          style="display: none"
        >
          Submit
        </CButton>
        <CButton
          @click="DeleteData()"
          color="danger"
          ref="DeletebtnRef"
          style="display: none"
        >
          Delete
        </CButton>
        <CButton
          @click="SaveData('Save')"
          color="primary"
          ref="saveDraftBtnRef"
          style="display: none"
        >
          Save Draft
        </CButton>
        <CButton
          @click="ChangeModal()"
          id="btnCancel"
          color="secondary"
          v-if="viewer === false"
          >Cancel</CButton
        >
      </template>
    </CModal>
    <!-- Modal  end-->

    <CModal
      :show.sync="IsShowInsightSelectModal"
      :close-on-backdrop="false"
      title="Select Insight"
      size="xl"
      color="primary"
    >
      <CDataTable
        :items="insightData"
        :fields="fields"
        table-filter
        items-per-page-select
        :items-per-page="5"
        hover
        sorter
        pagination
      >
        <template #selectbox="{ item }">
          <td>
            <CInputCheckbox
              :name="`IsSelected${item.InsightNo}`"
              :checked="item.IsSelected"
              @click="UpdateIsSelected(item.InsightNo, $event)"
            />
          </td>
        </template>
        <template #status="{ item }">
          <td>
            <CBadge
              style="background-color: #fbcbe1"
              v-if="item.Status == 'Waiting for review'"
            >
              {{ item.Status }}
            </CBadge>
            <CBadge :color="getBadge(item.Status)" v-else>
              {{ item.Status }}
            </CBadge>
          </td>
        </template>
      </CDataTable>

      <template #footer>
        <CButton @click="IsShowInsightSelectModal = false" color="secondary"
          >Cancel</CButton
        >
        <CButton @click="Confirmselected()" color="primary"
          >Confirm selected</CButton
        >
      </template>
    </CModal>

    <CModal
      :show.sync="ConfirmConceptModal"
      :close-on-backdrop="false"
      title="Add Concept ?"
      color="primary"
      centered
    >
      Do you want to create concept from this idea ?
      <template #header>
        <h6 class="modal-title">Add Concept</h6>
        <CButtonClose @click="ConfirmConceptModal = false" class="text-white" />
      </template>
      <template #footer>
        <CButton @click="ConfirmConceptModal = false" color="secondary"
          >No</CButton
        >
        <CButton @click="AddConcept()" color="primary">Yes</CButton>
      </template>
    </CModal>

    <ConceptModal
      :showModal.sync="ConceptModal"
      @ConceptModal-updated="ConceptModal = $event"
      :ideaDataSelected="ideaSelected"
      @ideaListSelected-updated="ideaSelected = $event"
      :viewmode="conceptMode"
    />

    <CModal
      :show.sync="confirmSubmitIdeaModal"
      :close-on-backdrop="false"
      title="Submit Idea?"
      color="primary"
      centered
    >
      Which business unit related to this idea?
      <template #header>
        <h6 class="modal-title">Submit Idea</h6>
        <CButtonClose
          @click="confirmSubmitIdeaModal = false"
          class="text-white"
        />
      </template>
      <br />
      <CRow>
        <CCol class="form-group form-row">
          <div class="col-md-3">
            <label class="">Business unit</label>
          </div>
          <div class="col-md-9 pr-0">
            <v-select
              v-model="ideaModel.BU"
              :value.sync="ideaModel.BU"
              :options="buOptions"
              placeholder="-- Select --"
              :disabled="disabled"
            />
          </div>
        </CCol>
      </CRow>

      <template #footer>
        <CButton @click="CancelSubmit()" color="secondary">No</CButton>
        <CButton @click="SaveData('Submit')" color="primary">Yes</CButton>
      </template>
    </CModal>

    <CModal
      :show.sync="confirmApproveIdeaModal"
      :close-on-backdrop="false"
      title="Approve Idea?"
      size="sm"
      color="primary"
      centered
    >
      Would you like to approve idea?
      <template #header>
        <h6 class="modal-title">Approve Idea</h6>
        <CButtonClose
          @click="confirmApproveIdeaModal = false"
          class="text-white"
        />
      </template>
      <template #footer>
        <CButton @click="CancelApprove()" color="secondary">No</CButton>
        <CButton @click="SaveData('Approve')" color="primary">Yes</CButton>
      </template>
    </CModal>

    <RejectModal
      :showModal.sync="showRejectModal"
      @RejectModal-Updated="showRejectModal = $event"
      :rejectModel.sync="rejectModel"
      @close-modal="ChangeModal(true)"
    />

    <CModal
      :show.sync="previewImgModal"
      :close-on-backdrop="false"
      title="Preview"
      size="lg"
      color="primary"
      centered
    >
      <template #header>
        <h6 class="modal-title">Preview</h6>
        <CButtonClose @click="previewImgModal = false" class="text-white" />
      </template>
      <CRow>
        <CCol class="form-group form-row">
          <div id="preview">
            <img v-if="urlImage" :src="urlImage" />
          </div>
        </CCol>
      </CRow>
      <template #footer>
        <CButton @click="previewImgModal = false" color="secondary"
          >Close</CButton
        >
      </template>
    </CModal>
    <Spinner :is-show="(isSpinner = hideLoader)" />
  </div>
</template>

<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { DatePicker } from "v-calendar";
import { uuid } from "vue-uuid";
import store from "../../store";
import axios from "axios";
import ConceptModal from "../concept/ConceptModal";
import RejectModal from "../RejectModal";
import Spinner from "../../components/Spinner";

var URL_InsertIdea = store.getters.URL + "/api/IdeationIdea/insert";
var URL_InsertMappingIdea = store.getters.URL + "/api/MappingIdea/insertlist";
var URL_UpdateIdea = store.getters.URL + "/api/IdeationIdea/update";
var URL_UpdateMappingIdea = store.getters.URL + "/api/MappingIdea/update";
var URL_DeleteIdea = store.getters.URL + "/api/IdeationIdea/delete";
var URL_Upload = store.getters.URL + "/api/IdeationIdea/uploadfile";
var URL_DeleteFile = store.getters.URL + "/api/IdeationAttachment/deletefile";
var URL_OpenFile = store.getters.URL + "/api/IdeationAttachment/openfile";
var URL_GetIdeaByID = store.getters.URL + "/api/IdeationIdea/getbyid";
var URL_GetMappingIdea = store.getters.URL + "/api/MappingIdea/getmappingidea";
var URL_GetAttachment =
  store.getters.URL + "/api/IdeationAttachment/getattachmentlist";
var URL_GetIndustry = store.getters.URL + "/api/MasterIndustry/getall";
var URL_GetGroup = store.getters.URL + "/api/MasterGroup/getall";
var URL_GetSegment = store.getters.URL + "/api/MasterSegment/getall";
var URL_GetSubSegment = store.getters.URL + "/api/MasterSubSegment/getall";
var URL_GetBu = store.getters.URL + "/api/MasterBu/getall";

var URL_GetSelectInsight =
  store.getters.URL + "/api/IdeationInsight/getselectinsight";

const fields = [
  {
    key: "selectbox",
    label: "Select",
    _style: "width:1%",
    sorter: false,
    filter: false,
  },
  { key: "InsightNo", label: "Insight No." },
  { key: "Industry", label: "Industry" },
  { key: "InsightDetail", label: "Insight Detail" },
  { key: "Creator", label: "Creator" },
  { key: "CreateDate", label: "Create Date" },
  { key: "InsightStatus", label: "Status" },
];

const fieldsSelected = [
  { key: "InsightNo", label: "Insight No." },
  { key: "InsightDetail", label: "Insight Detail" },
  { key: "InsightStatus", label: "Status" },
];

export default {
  name: "IdeaModal",
  components: {
    vSelect,
    DatePicker,
    //IdeaModal.vueInsightSelectModal
    ConceptModal,
    RejectModal,
    Spinner,
  },
  data() {
    return {
      fileExist: null,
      formCollapsed: true,
      IsShowInsightSelectModal: false,
      ConfirmConceptModal: false,
      ConceptModal: false,
      previewImgModal: false,
      urlImage: null,
      fieldsSelected,
      fields,
      insightData: [],
      ideaSelected: [],
      // files: null,
      files: [],
      attachments: [],
      buOptions: [{ value: "", label: "--Select--" }],
      industryOptions: [{ value: "", label: "--Select--" }],
      groupOptions: [{ value: "", label: "--Select--" }],
      segmentOptions: [{ value: "", label: "--Select--" }],
      segmentList: [],
      subSegmentOptions: [{ value: "", label: "--Select--" }],
      subSegmentList: [],
      disabled: false,
      disabledRelateBu: false,

      confirmSubmitIdeaModal: false,
      confirmApproveIdeaModal: false,
      submitObj: [],
      userFullName: "",
      username: "",
      IdeaProcessID: "",
      k2Obj: [],
      conceptMode: "create",
      // userToken: JSON.parse(localStorage.getItem("IdeationToken")),
      userToken: JSON.parse(localStorage.getItem("IdeationToken")),
      showRejectModal: false,
      rejectModel: {
        DocumentNo: "",
        ProcessID: "",
        RejectLevel: "",
        RejectUser: "",
        RejectComment: "",
      },
      isSpinner: false,
      hideLoader: false,
    };
  },
  props: {
    showModal: Boolean,
    viewmode: {
      type: String,
      default: "view",
      required: false,
    },
    insightDataSelected: {
      type: Array,
      required: false,
      default: () => [],
    },
    ideaModel: {
      type: Object,
      default: function () {
        return {
          IdeaNo: "",
          IdeaCreator: "",
          IdeaCreateDate: "",
          IdeaCreatorEmail: "",
          IdeaCreatorBU: "",
          IdeaEventFlag: false,
          IdeaIndustry: "",
          IdeaGroup: "",
          IdeaSegment: "",
          IdeaSubSegment: "",
          IdeaEventDate: new Date(),
          IdeaStatus: "",
          IdeaDetail: "",
          IdeaToSolve: "",
          IdeaWhom: "",
          CreateByUser: "",
          BU: "",
        };
      },
    },
    item: {
      type: Object,
      default: function () {
        return {
          IdeaNo: null,
          IdeaProcessID: null,
          IdeaStatus: null,
        };
      },
    },
    userProfile: {
      type: Object,
      default: function () {
        // return JSON.parse(localStorage.getItem("userprofile"));
        return JSON.parse(localStorage.getItem("userprofile"));
      },
    },
    onlyView: {
      type: Boolean,
      default: false,
    },
    viewer: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isshow: {
      get() {
        return this.showModal;
      },
      set(v) {
        this.$emit("IdeaModal-updated", v);
      },
    },
    InsightLinks: {
      get() {
        return this.insightDataSelected;
      },
      set(v) {
        this.$emit("insightListSelected-updated", v);
      },
    },
    userRole: function () {
      return this.userProfile.Role;
    },
    IdeaObject: {
      get() {
        return this.item;
      },
      set(v) {
        this.$emit("IdeaObject-updated", v);
      },
    },
  },
  mounted() {
    this.NewIdeaModel();
    this.$nextTick(() => {
      this.GetBu();
      this.GetIndustry();
      this.GetGroup();
      this.GetSegment();
      this.GetSubSegment();
      this.CheckPermission();
    });
    // setTimeout(
    //   function () {
    //     this.GetBu();
    //     this.GetIndustry();
    //     this.GetGroup();
    //     this.GetSubSegment();
    //   }.bind(this),
    //   500
    // );

    this.$refs.PwarningCanNotApprove.style.display = "none";
  },
  methods: {
    CheckPermission() {
      // console.log("userRole", this.userprofile);
      // console.log("viewmode", this.viewmode);
      // console.log("AppBtnRef", this.$refs.AppBtnRef.style.display);
      // console.log("item", this.item);

      const isAdmin = this.userRole == "Admin" ? true : false;
      this.$refs.AppBtnRef.style.display = "none";
      this.$refs.RejectBtnRef.style.display = "none";
      this.$refs.SubmitBtnRef.style.display = "none";
      this.$refs.DeletebtnRef.style.display = "none";
      this.$refs.saveDraftBtnRef.style.display = "none";
      this.disabledRelateBu = false;

      if (this.viewmode == "view") {
        this.$refs.AppBtnRef.style.display = "none";
        this.$refs.RejectBtnRef.style.display = "none";
        this.$refs.SubmitBtnRef.style.display = "none";
        this.$refs.DeletebtnRef.style.display = "none";
        this.$refs.saveDraftBtnRef.style.display = "none";
      } else if (this.viewmode == "create") {
        this.$refs.SubmitBtnRef.style.display = "";
        this.$refs.saveDraftBtnRef.style.display = "";
      } else if (this.viewmode == "edit") {
        if (this.item?.IdeaStatus == "Manager") {
          this.disabledRelateBu = true;
        }
        if (isAdmin) {
          if (this.item?.IdeaStatus == "Draft") {
            this.$refs.SubmitBtnRef.style.display = "";
            this.$refs.saveDraftBtnRef.style.display = "";
            this.$refs.DeletebtnRef.style.display = "";
          } else {
            this.$refs.AppBtnRef.style.display = "";
            this.$refs.RejectBtnRef.style.display = "";
          }
        } else {
          this.$refs.SubmitBtnRef.style.display = "";
          this.$refs.DeletebtnRef.style.display = "";
        }
      }
    },
    CheckCanApprove() {
      if (this.viewmode === "edit" && this.ideaModel.IdeaStatus === "Manager") {
        // console.log("CheckCanApprove")
        //console.log("insightData", this.insightData)//this.insightData.InsightStatus
        // console.log("AppBtnRef", this.$refs.AppBtnRef)
        // console.log("RejectBtnRef", this.$refs.RejectBtnRef)
        let result = this.insightData.every(
          (e) => e.InsightStatus == "Completed"
        );
        if (!result) {
          this.$refs.AppBtnRef.disabled = true;
          this.$refs.RejectBtnRef.disabled = true;
          this.$refs.PwarningCanNotApprove.style.display = "";
        } else {
          this.$refs.AppBtnRef.disabled = false;
          this.$refs.RejectBtnRef.disabled = false;
          this.$refs.PwarningCanNotApprove.style.display = "none";
        }
      }
    },
    AddRedStarToElement() {
      let name = ["IdeaDetail", "IdeaToSolve", "IdeaWhom"];
      const tempRedStar = '<span class="error">*</span>';
      Array.from(name).forEach((item) => {
        let temp = this.$refs[item].$children[0].$el.children[0].innerHTML;
        if (!temp.includes("</span>")) {
          this.$refs[
            item
          ].$children[0].$el.children[0].innerHTML = `${temp} ${tempRedStar}`;
        }
      });
    },
    SetViewMode(disabled) {
      this.disabled = disabled;
      this.disabledRelateBu = disabled;
      let elements = document.querySelectorAll("input,textarea");
      elements.forEach((element) => {
        if (element.id !== "txtRejectComment") element.disabled = disabled;
        // element.disabled = disabled;
      });
    },
    GetBu() {
      axios.get(URL_GetBu).then((res) => {
        let options = [{ value: "", label: "-- Select --" }];
        //console.log("res", res);
        if (res !== null) {
          res.data.forEach(function (data) {
            options.push({
              value: data.BUID,
              label: data.BUDesc,
            });
          });
        }
        this.buOptions = options;
      });
    },
    GetIndustry() {
      axios.get(URL_GetIndustry).then((res) => {
        let options = [{ value: "", label: "-- Select --" }];
        if (res !== null) {
          res.data.forEach(function (data) {
            options.push({
              value: data.IndustryID,
              label: data.IndustryDesc,
            });
          });
        }
        this.industryOptions = options;
      });
    },
    GetGroup() {
      axios.get(URL_GetGroup).then((res) => {
        let options = [{ value: "", label: "-- Select --" }];
        if (res !== null) {
          res.data.forEach(function (data) {
            options.push({
              value: data.GroupID,
              label: data.GroupDesc,
            });
          });
        }
        this.groupOptions = options;
      });
    },
    GetSegment() {
      axios.get(URL_GetSegment).then((res) => {
        if (res !== null) {
          this.segmentList = res.data;
          //this.CreateSegmentOptions(this.segmentList);
        }
      });
    },
    IndustryOnChange(event) {
      if (event !== null) {
        let segmentFilter = this.segmentList.filter(function (obj) {
          return obj.IndustryID == event.value;
        });
        if (
          event.label.toLowerCase() != "others" &&
          segmentFilter != null &&
          segmentFilter.length > 0
        ) {
          let ohterFilter = this.segmentList.filter(function (obj) {
            return obj.SegmentDesc.toLowerCase() == "others";
          });

          if (ohterFilter != null && ohterFilter.length > 0) {
            segmentFilter.push(ohterFilter[0]);
          }
        }

        this.CreateSegmentOptions(segmentFilter);
      }
    },
    GetSubSegment() {
      axios.get(URL_GetSubSegment).then((res) => {
        if (res !== null) {
          this.subSegmentList = res.data;
          //this.CreateSubSegmentOptions(this.subSegmentList);
        }
      });
    },
    SegmentOnChange(event) {
      if (event !== null) {
        var subSegmentFilter = this.subSegmentList.filter(function (obj) {
          return obj.SegmentID == event.value;
        });

        if (
          event.label.toLowerCase() != "others" &&
          subSegmentFilter != null &&
          subSegmentFilter.length > 0
        ) {
          let ohterFilter = this.subSegmentList.filter(function (obj) {
            return obj.SubSegmentDesc.toLowerCase() == "others";
          });

          if (ohterFilter != null && ohterFilter.length > 0) {
            subSegmentFilter.push(ohterFilter[0]);
          }
        }

        this.CreateSubSegmentOptions(subSegmentFilter);
      }
    },

    CreateSegmentOptions(segmentListI) {
      let options = [{ value: "", label: "-- Select --" }];

      var resSec = segmentListI.filter(function (x) {
        return x.SegmentDesc.toLowerCase() === "others";
      });

      //console.log("resSec", resSec);
      //console.log("SegmentFilter", segmentListI);
      if (resSec.length == 0) {
        let ohterFilter = this.segmentList.filter(function (obj) {
          return obj.SegmentDesc.toLowerCase() == "others";
        });

        if (ohterFilter != null && ohterFilter.length > 0) {
          segmentListI.push(ohterFilter[0]);
        }
      }

      segmentListI.forEach(function (data) {
        options.push({
          value: data.SegmentID,
          label: data.SegmentDesc,
        });
      });

      this.segmentOptions = options;
      this.subSegmentOptions = [{ value: "", label: "-- Select --" }];
      this.ideaModel.IdeaSegment = "";
      this.ideaModel.IdeaSubSegment = "";
    },
    CreateSubSegmentOptions(subSegmentListI) {
      let options = [{ value: "", label: "-- Select --" }];

      var resSubSec = subSegmentListI.filter(function (x) {
        return x.SubSegmentDesc.toLowerCase() === "others";
      });

      // console.log("resSec",resSec);
      // console.log("SegmentFilter",SegmentFilter);
      if (resSubSec.length == 0) {
        let ohterFilter = this.subSegmentList.filter(function (obj) {
          return obj.SubSegmentDesc.toLowerCase() == "others";
        });

        if (ohterFilter != null && ohterFilter.length > 0) {
          subSegmentListI.push(ohterFilter[0]);
        }
      }

      subSegmentListI.forEach(function (data) {
        options.push({
          value: data.SubSegmentID,
          label: data.SubSegmentDesc,
        });
      });

      this.subSegmentOptions = options;
      this.ideaModel.IdeaSubSegment = "";
    },
    GetnewObj() {
      let newObj = {
        ActionUser: null,
        IdeaNo: this.ideaModel.IdeaNo,
        CreateByUser: this.ideaModel.CreateByUser,
        IdeaCreator: this.ideaModel.IdeaCreator,
        IdeaCreateDate: this.ideaModel.IdeaCreateDate,
        IdeaCreatorEmail: this.ideaModel.IdeaCreatorEmail,
        IdeaCreatorBU: this.ideaModel.IdeaCreatorBU,
        BU: this.ideaModel.BU,
        IdeaStatus: this.ideaModel.IdeaStatus,
        IdeaEventDate: this.ideaModel.IdeaEventDate,
        IdeaEventFlag: this.ideaModel.IdeaEventFlag,
        IdeaIndustry: this.ideaModel.IdeaIndustry,
        IdeaGroup: this.ideaModel.IdeaGroup,
        IdeaSegment: this.ideaModel.IdeaSegment,
        IdeaSubSegment: this.ideaModel.IdeaSubSegment,
        IdeaDetail: this.ideaModel.IdeaDetail,
        IdeaToSolve: this.ideaModel.IdeaToSolve,
        IdeaWhom: this.ideaModel.IdeaWhom,
        insightData: this.ideaModel.insightData,
        InsightLinks: this.InsightLinks,
        files: this.ideaModel.files,
      };

      return newObj;
    },
    NewIdeaModel() {
      this.ideaModel.CreateByUser = this.userProfile.Username;
      this.ideaModel.IdeaCreator = this.userProfile.DisplayName;
      this.ideaModel.IdeaCreateDate = new Date()
        .toISOString()
        .slice(0, 10)
        .replace(/-/g, "/")
        .replace("T", " ");
      this.ideaModel.IdeaCreatorEmail = this.userProfile.Email;
      this.ideaModel.IdeaCreatorBU = this.userProfile.BU;
      this.ideaModel.BU = "";
      this.ideaModel.IdeaStatus = "Draft";
      this.ideaModel.IdeaEventDate = new Date();
      this.ideaModel.IdeaEventFlag = false;
      this.ideaModel.IdeaIndustry = "";
      this.ideaModel.IdeaGroup = "";
      this.ideaModel.IdeaSegment = "";
      this.ideaModel.IdeaSubSegment = "";
      this.ideaModel.IdeaDetail = "";
      this.ideaModel.IdeaToSolve = "";
      this.ideaModel.IdeaWhom = "";
      this.ideaModel.IdeaNo = "";

      this.insightData = [];
      this.InsightLinks = [];
      // this.files = null;
      this.files = [];
    },
    BindDatatoIdeaModel(data, isCopy = false) {
      //console.log(isCopy);
      if (isCopy) {
        this.NewIdeaModel();
        this.ideaModel.IdeaEventDate = data.IdeaEventDate;
        this.ideaModel.IdeaEventFlag = data.IdeaEventFlag;
        this.ideaModel.IdeaGroup =
          data.IdeaGroup !== null
            ? this.groupOptions[
                this.groupOptions.findIndex((x) => x.value === data.IdeaGroup)
              ]
            : "";
      } else {
        // console.log("data", data);
        this.ideaModel.CreateByUser = data.CreateByUser;
        this.ideaModel.IdeaCreator = data.IdeaCreator;
        this.ideaModel.IdeaCreateDate = data.IdeaCreateDate.substring(
          0,
          10
        ).replaceAll("-", "/");
        //console.log("data", data);
        //console.log("subSegmentList", this.subSegmentList);
        this.ideaModel.IdeaCreatorEmail = data.IdeaCreatorEmail;
        this.ideaModel.IdeaCreatorBU = data.IdeaCreatorBU;
        this.ideaModel.BU =
          data.BU !== null
            ? this.buOptions[
                this.buOptions.findIndex((x) => x.label === data.BU)
              ]
            : "";
        this.ideaModel.IdeaStatus = data.IdeaStatus;
        this.ideaModel.IdeaEventDate = data.IdeaEventDate;
        this.ideaModel.IdeaEventFlag = data.IdeaEventFlag;
        // this.ideaModel.IdeaIndustry =
        //   data.IdeaIndustry !== null
        //     ? this.industryOptions[
        //         this.industryOptions.findIndex(
        //           (x) => x.value === data.IdeaIndustry
        //         )
        //       ]
        //     : "";
        this.ideaModel.IdeaGroup =
          data.IdeaGroup !== null
            ? this.groupOptions[
                this.groupOptions.findIndex((x) => x.value === data.IdeaGroup)
              ]
            : "";

        // var segmentFilter = this.segmentList.filter(function (x) {
        //   return x.IndustryID === data.IdeaIndustry;
        // });
        // this.CreateSegmentOptions(segmentFilter);

        // var subSegmentList = this.subSegmentList.filter(function (x) {
        //   return x.SegmentID === data.IdeaSegment;
        // });
        // this.CreateSubSegmentOptions(subSegmentList);

        // this.ideaModel.IdeaSegment =
        //   data.IdeaSegment !== null
        //     ? this.segmentOptions[
        //         this.segmentOptions.findIndex(
        //           (x) => x.value === data.IdeaSegment
        //         )
        //       ]
        //     : "";
        // this.ideaModel.IdeaSubSegment =
        //   data.IdeaSubSegment !== null
        //     ? this.subSegmentOptions[
        //         this.subSegmentOptions.findIndex(
        //           (x) => x.value === data.IdeaSubSegment
        //         )
        //       ]
        //     : "";

        this.ideaModel.IdeaDetail = data.IdeaDetail;
        this.ideaModel.IdeaToSolve = data.IdeaToSolve;
        this.ideaModel.IdeaWhom = data.IdeaWhom;

        this.insightData = [];
        this.InsightLinks = [];
        // this.files = null;
        this.files = [];
      }

      this.ideaModel.IdeaIndustry =
        data.IdeaIndustry !== null
          ? this.industryOptions[
              this.industryOptions.findIndex(
                (x) => x.value === data.IdeaIndustry
              )
            ]
          : "";

      var segmentFilter = this.segmentList.filter(function (x) {
        return x.IndustryID === data.IdeaIndustry;
      });
      this.CreateSegmentOptions(segmentFilter);

      var subSegmentList = this.subSegmentList.filter(function (x) {
        return x.SegmentID === data.IdeaSegment;
      });
      this.CreateSubSegmentOptions(subSegmentList);

      this.ideaModel.IdeaSegment =
        data.IdeaSegment !== null
          ? this.segmentOptions[
              this.segmentOptions.findIndex((x) => x.value === data.IdeaSegment)
            ]
          : "";
      this.ideaModel.IdeaSubSegment =
        data.IdeaSubSegment !== null
          ? this.subSegmentOptions[
              this.subSegmentOptions.findIndex(
                (x) => x.value === data.IdeaSubSegment
              )
            ]
          : "";
    },
    GetIdeaModel(isCopy = false) {
      // Get IdeationIdea
      axios
        .get(URL_GetIdeaByID, {
          params: {
            uid: this.ideaModel.IdeaNo,
          },
        })
        .then((response) => {
          if (response !== null) {
            if (
              this.viewmode == "edit" &&
              response?.data?.CreateByUser?.toLowerCase() ==
                this.userProfile.Username.toLowerCase()
            ) {
              this.$refs.DeletebtnRef.style.display = "";
            }
            this.BindDatatoIdeaModel(response.data, isCopy);
          }
        })
        .finally(() => {
          // Get MappingIdea
          this.GetMappingIdea();
        });
    },
    GetMappingIdea() {
      axios
        .get(URL_GetMappingIdea, {
          params: {
            ideaNo: this.ideaModel.IdeaNo,
          },
        })
        .then((response) => {
          if (response !== null) {
            // var parsedobj = JSON.parse(JSON.stringify(response.data))
            // this.InsightLinks.splice(0, this.InsightLinks.length);
            // parsedobj.forEach((insight) =>
            //   let model = {
            //     InsightNo: insight.InsightNo,
            //     InsightDetail: insight.InsightDetail
            //   };
            //   this.InsightLinks.push(model);
            // });

            //this.insightData = response.data;
            this.insightData = this.ChangeWordingSataus(response.data);
            //console.log('insightData', insightData)
            this.InsightLinks = this.insightData.filter(
              (c) => c.IsSelected === true
            );
          }
        })
        .finally(() => {
          // Get IdeationAttachment
          this.CheckCanApprove();
          this.GetIdeationAttachment();
        });
    },
    GetIdeationAttachment() {
      // Get IdeationAttachment
      axios
        .get(URL_GetAttachment, {
          params: {
            documentID: this.ideaModel.IdeaNo,
          },
        })
        .then((response) => {
          if (response !== null) {
            this.attachments = response.data;
            // response.data.forEach((attachment) => {
            //   this.attachments.push(attachment.AttachmentName); // Add file name to list
            // });
          }
        });
    },
    SaveData(action) {
      this.hideLoader = true;
      if (action != "Save") {
        let isNull = false;
        if (
          this.ideaModel.BU == "" ||
          this.ideaModel.BU == null ||
          this.ideaModel.BU == undefined
        ) {
          isNull = true;
        } else {
          if (this.ideaModel.BU.value == "") {
            isNull = true;
          }
        }

        if (isNull) {
          this.$_toast_Noti("error", "error", "Please select relation bu !");
          this.hideLoader = false;
          return;
        }

        if (this.ideaModel.IdeaDetail === "") {
          this.$refs.IdeaDetail.$el.children[0].focus();
          this.hideLoader = false;
          return;
        } else if (this.ideaModel.IdeaToSolve === "") {
          this.$refs.IdeaToSolve.$el.children[0].focus();
          this.hideLoader = false;
          return;
        } else if (this.ideaModel.IdeaWhom === "") {
          this.$refs.IdeaWhom.$el.children[0].focus();
          this.hideLoader = false;
          return;
        } else if (this.ideaModel.IdeaIndustry === "") {
          this.$_toast_Noti("error", "error", "Please select industry !");
          this.$refs.RefIdeaVSelect.$el.children[0].focus();
          this.hideLoader = false;
          return;
        }

        if (this.InsightLinks.length === 0) {
          this.$_toast_Noti("error", "error", "Please select insight !");
          this.hideLoader = false;
          return;
        }
      }
      //console.log(this.ideaModel);
      (this.ideaModel.ActionUser = this.userProfile.DisplayName),
        (this.ideaModel.BU =
          this.ideaModel.BU === undefined
            ? this.ideaModel.BU
            : this.ideaModel.BU.label);
      this.ideaModel.IdeaIndustry =
        this.ideaModel.IdeaIndustry === undefined
          ? this.ideaModel.IdeaIndustry
          : this.ideaModel.IdeaIndustry.value;
      this.ideaModel.IdeaGroup =
        this.ideaModel.IdeaGroup === undefined
          ? this.ideaModel.IdeaGroup
          : this.ideaModel.IdeaGroup.value;
      this.ideaModel.IdeaSegment =
        this.ideaModel.IdeaSegment === undefined
          ? this.ideaModel.IdeaSegment
          : this.ideaModel.IdeaSegment.value;
      this.ideaModel.IdeaSubSegment =
        this.ideaModel.IdeaSubSegment === undefined
          ? this.ideaModel.IdeaSubSegment
          : this.ideaModel.IdeaSubSegment.value;
      this.ideaModel.IdeaCreateDate = null;

      this.ideaModel.IdeaSegment =
        this.ideaModel.IdeaSegment == "" ? null : this.ideaModel.IdeaSegment;

      this.ideaModel.IdeaSubSegment =
        this.ideaModel.IdeaSubSegment == ""
          ? null
          : this.ideaModel.IdeaSubSegment;

      if (this.ideaModel.IdeaStatus === "Draft" && action === "Submit") {
        this.ideaModel.IdeaStatus = "Draft,Submit";
      } else {
        this.ideaModel.IdeaStatus = action === "Save" ? "Draft" : action;
      }

      //console.log('this.ideaModel', this.ideaModel);
      this.hideLoader = true;
      if (this.viewmode === "create") {
        // Insert IdeationIdea
        //console.log("ideaModel", this.ideaModel)
        axios
          .put(URL_InsertIdea, this.ideaModel)
          .then((response) => {
            if (response.data.StatusCode === 200) {
              //console.log('response in idea', response);

              this.ideaModel.IdeaNo = response.data.Data.IdeaNo;

              let newObj = this.GetnewObj();

              // if (action === "Submit"){
              //   this.k2Obj.push({
              //     DocumentNO: this.ideaModel.IdeaNo,
              //     CreatorName: this.ideaModel.IdeaCreator,
              //     UserCreator: this.ideaModel.CreateByUser,
              //     Action: action
              //   });
              // }
              if (response.data.Data.IsError) {
                this.$_toast_Noti(
                  "warn",
                  "warn",
                  response.data.Data.Message[0]
                );
              }
              this.ideaSelected = [];
              this.ideaSelected.push(newObj);
              this.$emit("save-success");
              //this.IdeaObject = newObj;
              //console.log("SetIdeaObject");

              // Insert MappingIdea
              this.InsertMappingIdea(action);
            } else {
              this.$_toast_Noti("error", "error", response.data.Message);
            }
          })
          .catch((error) => {
            this.$_toast_Noti("error", "error", error);
          })
          .finally(() => {
            this.hideLoader = false;
          });
      } else {
        //if (this.viewmode === "edit")
        // Update IdeationIdea
        axios
          .post(URL_UpdateIdea, this.ideaModel)
          .then((response) => {
            if (response.data.StatusCode === 200) {
              //console.log('Update IdeationIdea Success');
              //console.log('response up idea', response);

              if (response.data.Data.IsError) {
                this.$_toast_Noti(
                  "warn",
                  "warn",
                  response.data.Data.Message[0]
                );
              }
              let newObj = this.GetnewObj();

              this.ideaSelected = [];
              this.ideaSelected.push(newObj);

              this.$emit("save-success");
              //this.IdeaObject = newObj;
              //console.log("SetIdeaObject");
              this.UpdateMappingIdea(action);
            } else {
              this.$_toast_Noti("error", "error", response.data.Message);
            }
          })
          .catch((error) => {
            this.$_toast_Noti("error", "error", error);
          })
          .finally(() => {
            this.hideLoader = false;
          });
      }
      if (action === "Submit") {
        this.ConfirmConceptModal = true;
      }
    },
    InsertMappingIdea(action) {
      let modelList = [];
      this.hideLoader = true;
      this.InsightLinks.forEach((insight) => {
        let model = {
          IdeaNo: this.ideaModel.IdeaNo,
          InsightNo: insight.InsightNo,
        };
        modelList.push(model);
      });
      //console.log("modelList", modelList)
      // this.InsertIdeationAttachment();
      axios
        .put(URL_InsertMappingIdea, modelList)
        .then((response) => {
          if (response.data.StatusCode === 200) {
            //console.log('Save MappingIdea Success');

            // Insert IdeationAttachment
            this.InsertIdeationAttachment();

            // จะ Success ก็ต่อเมื่อ Save Success เท่านั้น
            this.$_toast_Noti("success", "success", "Save Success");

            // ====> K2
            // if (action === "Submit") {
            //   this.ConfirmK2Service(this.k2Obj);
            // } else {
            //   this.ChangeModal(); // Close Modal เมื่อ Save Success เท่านั้น
            // }
            // ====> K2
            this.hideLoader = false;
            this.confirmSubmitIdeaModal = false;
            this.confirmApproveIdeaModal = false;
            this.ChangeModal(); // Close Modal เมื่อ Save Success เท่านั้น
          } else {
            this.$_toast_Noti("error", "error", response.data.Message);
          }
        })
        .catch((error) => {
          this.$_toast_Noti("error", "error", error);
        });
    },
    InsertIdeationAttachment() {
      if (this.files !== null) {
        let formData = new FormData(); // Create FormData for upload file

        this.files.forEach((file) => {
          let filename = this.GenerateFileName(file.name); // Create file name
          formData.append(this.ideaModel.IdeaNo, file, filename); // Add file for upload
        });

        // Upload File
        this.UploadFile(formData);
      }
    },
    UpdateMappingIdea(action) {
      this.hideLoader = true;
      let modelList = [];

      this.InsightLinks.forEach((insight) => {
        let model = {
          IdeaNo: this.ideaModel.IdeaNo,
          InsightNo: insight.InsightNo,
        };
        modelList.push(model);
      });

      axios
        .post(URL_UpdateMappingIdea, modelList)
        .then((response) => {
          if (response.data.StatusCode === 200) {
            //console.log("Update MappingIdea Success");

            // Delete IdeationAttachment
            this.DeleteIdeationAttachment();

            // Insert IdeationAttachment
            this.InsertIdeationAttachment();

            this.$_toast_Noti("success", "success", "Save Success");

            this.hideLoader = false;
            this.confirmSubmitIdeaModal = false;
            this.confirmApproveIdeaModal = false;
            this.ChangeModal();

            //K2 Action//
            // if (action === "Submit") {
            //   this.k2Obj.push({
            //     DocumentNO: this.ideaModel.IdeaNo,
            //     CreatorName: this.ideaModel.IdeaCreator,
            //     UserCreator: this.ideaModel.CreateByUser,
            //     Action: action,
            //   });
            //   this.ConfirmK2Service(this.k2Obj);
            // } else if (action === "Approve") {
            //   this.k2Obj.push({
            //     Username: this.userProfile.Username,
            //     Token: this.userToken.token,
            //     ProcessID: this.ideaProcessID,
            //     Action: action,
            //   });
            //   this.ConfirmK2Service(this.k2Obj);
            // } else {
            //   this.ChangeModal();
            // }
          } else {
            this.$_toast_Noti("error", "error", response.data.Message);
          }
        })
        .catch((error) => {
          this.$_toast_Noti("error", "error", error);
        });
    },
    DeleteIdeationAttachment() {
      axios
        .post(URL_DeleteFile, this.attachments)
        .then((response) => {
          if (response.data.StatusCode === 200) {
            //console.log("Delete File Success");
          } else {
            this.$_toast_Noti("error", "error", response.data.Message);
          }
        })
        .catch((error) => {
          this.$_toast_Noti("error", "error", error);
        });
    },
    DeleteData() {
      if (confirm("Do you want to delete?")) {
        this.ideaModel.IdeaIndustry = "";
        this.ideaModel.IdeaGroup = "";

        // Delete IdeationIdea
        axios
          .post(URL_DeleteIdea, null, {
            params: { ideaNo: this.ideaModel.IdeaNo },
          })
          .then((response) => {
            if (response.data.StatusCode === 200) {
              //console.log("Delete IdeationIdea Success");

              //let newObj = this.GetnewObj();
              //newObj.FlagDelete = true;
              //this.IdeaObject = newObj;
              this.$emit("save-success");

              this.$_toast_Noti("success", "success", "Delete Success");
              this.ChangeModal();
            } else {
              this.$_toast_Noti("error", "error", response.data.Message);
            }
          })
          .catch((error) => {
            this.$_toast_Noti("error", "error", error);
          });
      }
    },
    UploadFile(formData) {
      axios
        .post(URL_Upload, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.StatusCode === 200) {
            //console.log('Save File Success');
          } else {
            this.$_toast_Noti("error", "error", response.data.Message);
          }
        })
        .catch((error) => {
          this.$_toast_Noti("error", "error", error);
        })
        .finally(() => {});
    },
    OpenFile(file, isExist) {
      //URL_OpenFile
      if (isExist) {
        axios
          .get(URL_OpenFile + `?filename=${file}`, {
            headers: { responseType: "arraybuffer" },
          })
          .then((response) => {
            if (response.status === 200) {
              let result = this.CheckOpenFile(response.data, isExist); //global Func
              if (result.isImage) {
                this.urlImage = result.urlImage;
                this.previewImgModal = true;
              }
            } else {
              this.$_toast_Noti("error", "error", response.data.Message);
            }
          })
          .catch((error) => {
            this.$_toast_Noti("error", "error", error);
          });
      }
      this.urlImage = null;
      let result = isExist
        ? this.CheckOpenFile(null)
        : this.CheckOpenFile(file); //global Func
      if (result.isImage) {
        this.urlImage = result.urlImage;
        this.previewImgModal = true;
      }
    },
    fileChange(fileList) {
      var IsValidate = true;
      Array.from(fileList).forEach((file) => {
        if (file.size > 5242880) {
          this.$_toast_Noti(
            "error",
            "error",
            "Cannot upload file more than 5 MB"
          );
          IsValidate = false;
        }
      }); //.bind(this));

      if (IsValidate) {
        // this.files = fileList;
        Array.from(fileList).forEach((file) => {
          if (this.files.findIndex((f) => f.name == file.name) === -1) {
            this.files.push(file);
          }
        });

        document.getElementById(this.$refs["inputfile"].safeId).value = null;
      }

      // console.log('files', this.files)
    },
    GenerateFileName(filename) {
      return (
        this.$uuid.v4() + "/" + filename // + "." + filename.substr(filename.lastIndexOf(".") + 1)
      );
    },
    ConfirmDeleteFileBeforeSave(key) {
      if (confirm("Do you want to delete?")) {
        this.files.splice(key, 1);
      }
    },
    ConfirmDeleteFile(attachment) {
      if (confirm("Do you want to delete?")) {
        attachment.IsDelete = true;
      }
    },
    EventFlagClick(event) {
      this.ideaModel.IdeaEventFlag = event.target.checked;
    },
    ChangeModal(isReject) {
      this.viewmode === "view" && this.SetViewMode(false);
      this.NewIdeaModel();
      this.isshow = !this.isshow;
      if (isReject) {
        this.$emit("save-success");
      }

      // window.location.reload();
    },
    getBadge(status) {
      switch (status) {
        case "Draft":
          return "secondary";
        case "Waiting for approve":
          return "warning";
        case "Approved":
          return "success";
        // case "Waiting for review":hideLoader
        //   return "primary";
        default:
          "primary";
      }
    },
    ChangeWordingSataus(insightData) {
      let tempdata = insightData;
      Array.from(tempdata).forEach((e) => {
        if (e.InsightStatus == "Manager") {
          e.InsightStatus = this.$store.getters.Status.WaitApprove;
        } else if (e.InsightStatus == "Request") {
          e.InsightStatus = this.$store.getters.Status.WaitEdit;
        }
      });
      return tempdata;
    },
    searchInsight() {
      this.IsShowInsightSelectModal = true;
      axios
        .get(URL_GetSelectInsight, {
          params: { username: this.userProfile.Username },
        })
        .then((response) => {
          if (response !== null) {
            //this.insightData = response.data;
            this.insightData = this.ChangeWordingSataus(response.data);
            //console.log('insightData', this.insightData)
            this.InsightLinks.forEach((obj, index) => {
              let currentIndex = this.insightData.findIndex(
                (t) => t.InsightNo == obj.InsightNo
              );
              if (currentIndex > -1) {
                this.insightData[currentIndex].IsSelected = true;
              }
            });
          }
        })
        .catch((error) => {
          this.$_toast_Noti("error", "error", error);
        });
    },
    Confirmselected() {
      this.InsightLinks = this.insightData.filter((c) => c.IsSelected === true);
      this.IsShowInsightSelectModal = false;
    },
    UpdateIsSelected(id, event) {
      let currentIndex = this.insightData.findIndex((t) => t.InsightNo == id);
      if (currentIndex > -1) {
        this.insightData[currentIndex].IsSelected = event.target.checked;
      }
    },
    Submit() {
      this.ChangeModal();
      this.ConfirmConceptModal = true;
    },
    AddConcept() {
      this.hideLoader = true;
      setTimeout(
        function () {
          this.ConfirmConceptModal = false;
          //console.log('ideaSelected',this.ideaSelected);
          this.ideaSelected[0].IdeaStatus =
            this.$store.getters.Status.WaitApprove;
          this.ConceptModal = true;
          this.hideLoader = false;
        }.bind(this),
        2500
      );
    },
    K2Action(action) {
      switch (action) {
        case "Submit":
          //this.k2Obj.splice(0);
          //this.confirmSubmitIdeaModal = true;
          this.SaveData(action);
          break;
        case "Approve":
          //this.k2Obj.splice(0);
          this.confirmApproveIdeaModal = true;
          break;
        default:
          break;
        case "Reject":
          this.rejectModel = {
            DocumentNo: this.ideaModel.IdeaNo,
            ProcessID: "", //this.ideaProcessID,
            RejectLevel: "Manager",
            RejectUser: this.userProfile.DisplayName,
            RejectComment: "",
          };
          this.showRejectModal = true;
          break;
      }
    },
    ConfirmK2Service(k2Obj) {
      this.hideLoader = true;
      switch (k2Obj[0].Action) {
        case "Submit":
          //console.log("ObjSubmit", k2Obj);
          axios
            .put(store.getters.URL + "/api/IdeationIdea/submitidea", k2Obj)
            .then((res) => {
              if (res.data.StatusCode === 200) {
                if (res.data.Message === "Success") {
                  this.$_toast_Noti(
                    "success",
                    "success",
                    "Your idea was submitted."
                  );
                  this.confirmSubmitIdeaModal = false;
                  this.ChangeModal();
                  this.ConfirmConceptModal = true;
                  this.$emit("save-success");
                } else if (res.data.Message === "Error") {
                  this.$_toast_Noti(
                    "error",
                    "error",
                    "Cannot submit idea.Please try again later.!"
                  );
                  this.confirmSubmitIdeaModal = false;
                }
              } else {
                this.$_toast_Noti("error", "error", res.data.Message);
              }
              this.hideLoader = false;
            })
            .catch((err) => {
              console.error("ErrorRes", err.response);
              this.$_toast_Noti("error", "error", err.response);
              this.confirmSubmitIdeaModal = false;
              this.hideLoader = false;
            });

          break;
        case "Approve":
          //console.log("ObjApprove", k2Obj);
          axios
            .put(store.getters.URL + "/api/IdeationIdea/approveidea", k2Obj)
            .then((res) => {
              if (res.data.StatusCode === 200) {
                if (res.data.Message === "Success") {
                  this.$_toast_Noti(
                    "success",
                    "success",
                    "Your idea was approved."
                  );
                  this.confirmApproveIdeaModal = false;

                  setTimeout(
                    function () {
                      this.$emit("save-success");
                      this.hideLoader = false;
                      this.ChangeModal();
                    }.bind(this),
                    3000
                  );
                } else if (res.data.Message === "Error") {
                  this.$_toast_Noti(
                    "error",
                    "error",
                    "Cannot approve idea.Please try again later.!"
                  );
                  this.confirmApproveIdeaModal = false;
                  this.hideLoader = false;
                } else {
                  this.$_toast_Noti("error", "error", res.data.Message);
                  this.confirmApproveIdeaModal = false;
                  this.hideLoader = false;
                }
              } else {
                //console.log("Error",res.data.Message)
                this.$_toast_Noti("error", "error", res.data.Message);
                this.confirmApproveIdeaModal = false;
                this.hideLoader = false;
              }
            })
            .catch((err) => {
              this.$_toast_Noti("error", "error", err.response);
              this.confirmApproveIdeaModal = false;
              this.hideLoader = false;
            });
          this.k2Obj = null;
          break;
        default:
          break;
      }
    },
    CancelSubmit() {
      this.confirmSubmitIdeaModal = false;
      //this.ConfirmIdeaModal = true;
    },
    CancelApprove() {
      this.confirmApproveIdeaModal = false;
    },
    CheckIndustrySelect() {
      let name = "RefIdeaVSelect";
      let name2 = "RefVSelectBU";
      if (this.$refs[name] == undefined || this.$refs[name2] == undefined) {
        return;
      }
      if (this.ideaModel.IdeaIndustry == "") {
        this.$refs[name].$el.children[0].style.borderColor = "red";
      } else {
        this.$refs[name].$el.children[0].style.borderColor = "";
      }
      if (this.ideaModel.BU == null || this.ideaModel.BU == "") {
        this.$refs[name2].$el.children[0].style.borderColor = "red";
      } else {
        this.$refs[name2].$el.children[0].style.borderColor = "";
      }
    },
  },
  watch: {
    showModal: function (newVal) {
      if (newVal == true) {
        this.CheckIndustrySelect();
        this.AddRedStarToElement();
      }
    },
    ideaModel: {
      handler: function (newValue) {
        this.CheckIndustrySelect();
      },
      deep: true,
    },
    item: function (newItem, oldItem) {
      setTimeout(
        function () {
          this.ideaModel.IdeaNo = newItem.IdeaNo;
          this.ideaProcessID = newItem.IdeaProcessID;
          //console.log("IsCopy",newItem.IsCopy)
          this.GetIdeaModel(newItem.IsCopy);
          this.viewmode === "view" && this.SetViewMode(true);
          this.CheckPermission();
          //console.log("Token",this.userToken)
        }.bind(this),
        1000
      );
    },
  },
};
</script>
