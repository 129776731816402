<template>
  <CCard>
    <CCardHeader><h4 class="subhead-modal">Insight Tank</h4></CCardHeader>
    <CCardBody>
      <CRow class="mb-2">
        <CCol sm="10">
          <CLink
            class="card-header-action btn-minimize"
            @click="formCollapsed = !formCollapsed"
          >
            <CIcon
              size="lg"
              name="cil-chevron-double-down"
              v-if="formCollapsed"
            />
            <CIcon size="lg" name="cil-chevron-double-up" v-else />
          </CLink>
          <label><b>Search box</b></label>
        </CCol>
      </CRow>

      <CCollapse :show="formCollapsed">
        <CRow>
          <CCol md="4">
            <CCard class="card border-secondary">
              <CCardHeader>
                <label>Creator details</label>
              </CCardHeader>
              <CCardBody>
                <CInput label="Creator" v-model="searchObj.Creator" />
                <CSelect
                  label="Business Unit"
                  :options="buList"
                  placeholder=""
                  v-model="searchObj.BU"
                  :value.sync="searchObj.BU"
                />
              </CCardBody>
            </CCard>
          </CCol>
          <CCol md="4">
            <CCard class="card border-secondary">
              <CCardHeader>
                <label>Market / Customer</label>
              </CCardHeader>
              <CCardBody>
                <CSelect
                  label="Industry"
                  :options="IndustryList"
                  placeholder=""
                  v-model="searchObj.Industry"
                  :value.sync="searchObj.Industry"
                />
                <CSelect
                  label="Segment"
                  :options="SegmentList"
                  placeholder=""
                  v-model="searchObj.Segment"
                  @change="segmentOnChange($event)"
                  :value.sync="searchObj.Segment"
                />
                <CSelect
                  label="Sub Segment"
                  :options="SubsegmentFilter"
                  placeholder=""
                  v-model="searchObj.SubSegment"
                  :value.sync="searchObj.SubSegment"
                />
              </CCardBody>
            </CCard>
          </CCol>
          <CCol md="4">
            <CCard class="card border-secondary">
              <CCardHeader><label>Insight detail</label></CCardHeader>
              <CCardBody>
                <CInput label="Insight No." v-model="searchObj.InsightNo" />
                <CSelect
                  label="Lens"
                  :options="LensList"
                  placeholder=""
                  v-model="searchObj.Lens"
                  :value.sync="searchObj.Lens"
                />
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CCollapse>
      <CRow>
        <CCol></CCol>
        <CCol class="text-center">
          <CInput label="Keyword" horizontal v-model="searchObj.InsightDetail">
            <template #prepend-content
              ><CIcon name="cil-magnifying-glass"
            /></template>
          </CInput>
        </CCol>
        <CCol></CCol>
      </CRow>
      <CRow>
        <CCol class="text-center">
          <CButton color="info-custom" class="mr-1" @click="OnExport()"
            >Export</CButton
          >
          <CButton color="dark" class="mr-1 text-white" @click="ClearValue()"
            >Clear</CButton
          >
          <CButton color="primary" class="text-white" @click="SearchInsight()"
            >Search</CButton
          >
        </CCol>
      </CRow>
      <CRow>
        <CCol md="12">
          <CDataTable
            :items="InsightTankData"
            :fields="fields"
            items-per-page-select
            :items-per-page="10"
            :loading="IsLoading"
            hover
            sorter
            pagination
          >
            <template #action="item">
              <td>
                <CButton
                  v-if="item.item.ILike"
                  size="sm"
                  color="success-custom"
                  variant="ghost"
                  class="mr-1 active"
                  @click="onClickLike(item.item)"
                >
                  <CIcon name="cil-thumb-up"></CIcon>
                </CButton>
                <CButton
                  v-else
                  size="sm"
                  color="success-custom"
                  variant="ghost"
                  class="mr-1"
                  @click="onClickLike(item.item)"
                >
                  <CIcon name="cil-thumb-up"></CIcon>
                </CButton>

                <CButton
                  size="sm"
                  color="info"
                  v-on:click="onClickAddIdea(item.item)"
                >
                  Add Idea
                </CButton>
              </td>
            </template>

            <template #InsightNo="{ item }">
              <td>
                <CLink class="text-primary" @click="OnClickDetail(item)">{{
                  item.InsightNo
                }}</CLink>
              </td>
            </template>
          </CDataTable>
        </CCol>
      </CRow>
    </CCardBody>

    <IdeaModal
      :showModal.sync="IdeaModal"
      @IdeaModal-updated="IdeaModal = $event"
      :viewmode="ideaViewMode"
      :insightDataSelected="insightSelected"
      @insightListSelected-updated="insightSelected = $event"
    />

    <InsightModal
      :showModal.sync="InsightModal"
      :item.sync="InsightObj"
      @InsightModal-updated="InsightModal = $event"
      :viewmode="InsightViewmode"
    />
  </CCard>
</template>

<script>
import InsightModal from "@/views/insight/InsightModal";
import IdeaModal from "../idea/IdeaModal";
import axios from "axios";
import store from "../../store";
import { Url } from "url";
var URLLogLikeInsert = store.getters.URL + "/api/LogLike/insert";
var URLLogLikeDelete = store.getters.URL + "/api/LogLike/delete";

const fields = [
  { key: "InsightNo", label: "Insight No.", _style: "text-align:center" },
  { key: "IndustryDesc", label: "Industry" },
  { key: "SegmentDesc", label: "Segment" },
  { key: "InsightDetail", label: "Insight detail" },
  { key: "InsightCreator", label: "Creator" },
  { key: "ShowApprovedDate", label: "Approved Date", _classes: "text-center" },
  //{ key: "status", label: "Status" },
  {
    key: "action",
    label: "Action",
    sorter: false,
    filter: false,
  },
];
export default {
  name: "InsightTank",
  components: {
    InsightModal,
    IdeaModal,
  },
  data() {
    return {
      ideaViewMode: "create",
      SelectOption: [{ value: "", label: "" }],
      fields,
      formCollapsed: false,
      ConceptModal: false,
      InsightModal: false,
      InsightObj: null,
      TypeConcept: "Insight",
      IdeaModal: false,
      InsightViewmode: "view",
      IndustryList: [{ value: null, label: "--Select--" }],
      SegmentList: [{ value: null, label: "--Select--" }],
      SubSegmentList: [{ value: null, label: "--Select--" }],
      buList: [{ value: null, label: "--Select--" }],
      LensList: [],
      searchObj: {
        Segment: "",
        SubSegment: "",
        Lens: "",
        Industry: "",
        Creator: "",
        BU: "",
        InsightDetail: "",
        InsightNo: "",
      },
      SubsegmentFilter: [{ value: null, label: "--Select--" }],
      InsightTankData: [],
      IsLoading: true,
      // userprofile: JSON.parse(localStorage.getItem("userprofile")),
      userprofile: JSON.parse(localStorage.getItem("userprofile")),
      insightSelected: [],
    };
  },
  methods: {
    GetBu() {
      axios.get(store.getters.URL + "/api/MasterBu/getall").then((res) => {
        let options = [{ value: "", label: "-- Select --" }];
        //console.log("res", res);
        if (res !== null) {
          res.data.forEach(function (data) {
            options.push({
              value: data.BUID,
              label: data.BUDesc,
            });
          });
        }
        this.buList = options;
      });
    },
    OnExport() {
      this.IsLoading = true;
      let item = {
        Creator: this.searchObj.Creator,
        BU: this.searchObj.BU,
        Industry: this.searchObj.Industry,
        Segment: this.searchObj.Segment,
        SubSegment: this.searchObj.SubSegment,
        Lens: this.searchObj.Lens,
        InsightNo: this.searchObj.InsightNo,
        InsightDetail: this.searchObj.InsightDetail,
        Username: this.userprofile.Username,
      };
      axios
        .get(store.getters.URL + "/api/IdeationInsight/ExportInsightData", {
          params: item,
          responseType: "blob",
        })
        .then((res) => {
          //console.log("da res", res);
          const blob = new Blob([res.data], {
            type: res.headers["content-type"],
          });
          const ojectUrl = URL.createObjectURL(blob);
          this.OnExportToExcel(ojectUrl, "InsightReport.xlsx");
          this.IsLoading = false;
        })
        .catch((err) => {
          console.error(err);
          this.IsLoading = false;
        });
      // let dataForExport = [];
      // Array.from(this.InsightTankData).forEach((item) => {
      //     let format = {
      //       InsightNo: item.InsightNo,
      //       Industry: item.IndustryDesc,
      //       Segment: item.SegmentDesc,
      //       InsightDetail: item.InsightDetail,
      //       Creator: item.InsightCreator,
      //       ApprovedDate: item.ShowApprovedDate
      //     };
      //     dataForExport.push(format)
      //   });
      // this.OnExportToExcel("InsightTank", dataForExport)
    },
    OnClickDetail(item) {
      //console.log("item",item);
      this.InsightModal = !this.InsightModal;
      this.InsightObj = item;
      //InsightModal.LoadById(item.insightno);
    },
    onClickAddIdea(item) {
      //console.log('item', item);

      let model = {
        InsightNo: item.InsightNo,
        InsightDetail: item.InsightDetail,
      };

      this.insightSelected = [];
      this.insightSelected.push(model);

      this.IdeaModal = true;
    },
    GetIndustry() {
      axios
        .get(store.getters.URL + "/api/MasterIndustry/getall")
        .then((res) => {
          let Options = [{ value: null, label: "-- Select --" }];
          res.data.forEach(function (data) {
            Options.push({
              value: data.IndustryID,
              label: data.IndustryDesc,
            });
          });
          this.IndustryList = Options;
        });
    },
    GetSegment() {
      axios.get(store.getters.URL + "/api/MasterSegment/getall").then((res) => {
        let Options = [{ value: null, label: "-- Select --" }];
        res.data.forEach(function (data) {
          Options.push({
            value: data.SegmentID,
            label: data.SegmentDesc,
          });
        });
        this.SegmentList = Options;
      });
    },
    GetSubSegment() {
      axios
        .get(store.getters.URL + "/api/MasterSubSegment/getall")
        .then((res) => {
          this.SubSegmentList = res.data;
        });
    },
    GetLens() {
      axios.get(store.getters.URL + "/api/MasterLens/getall").then((res) => {
        let Options = [{ value: null, label: "--Select--" }];
        res.data.forEach(function (data) {
          let LensID = String(data.LensID) || "";
          Options.push({
            value: LensID,
            label: data.LensDesc,
          });
        });
        this.LensList = Options;
      });
    },
    segmentOnChange(event) {
      var MySubSegmentList = this.SubSegmentList.filter(function (obj) {
        return obj.SegmentID == event.target.value;
      }); //.filter(x => x.SegmentID = event.target.value)

      let Options = [{ value: null, label: "--Select--" }];
      MySubSegmentList.forEach(function (data) {
        Options.push({
          value: data.SubSegmentID,
          label: data.SubSegmentDesc,
        });
      });

      this.SubsegmentFilter = Options;

      this.searchObj.SubSegment = null;
    },
    GetInsightData() {
      axios
        .get(store.getters.URL + "/api/IdeationInsight/getinsighttankdata", {
          params: {
            username: this.userprofile.Username,
          },
        })
        .then((res) => {
          //console.log(res.data.Value)
          let Obj = JSON.parse(res.data.Value);
          this.InsightTankData = Obj;
          this.IsLoading = false;
        })
        .catch((err) => {
          console.error(err);
          this.IsLoading = false;
        });
    },
    SearchInsight() {
      this.IsLoading = true;
      let item = {
        Creator: this.searchObj.Creator,
        BU: this.searchObj.BU,
        Industry: this.searchObj.Industry,
        Segment: this.searchObj.Segment,
        SubSegment: this.searchObj.SubSegment,
        Lens: this.searchObj.Lens,
        InsightNo: this.searchObj.InsightNo,
        InsightDetail: this.searchObj.InsightDetail,
        Username: this.userprofile.Username,
      };
      axios
        .get(store.getters.URL + "/api/IdeationInsight/searchinsighttankdata", {
          params: item,
        })
        .then((res) => {
          //console.log(res.data);
          this.InsightTankData = res.data;
          this.IsLoading = false;
        })
        .catch((err) => {
          console.error(err.error);
          this.IsLoading = false;
        });
      //console.log(this.searchObj.Industry)
    },
    ClearValue() {
      this.searchObj.Creator = "";
      this.searchObj.BU = "";
      this.searchObj.Industry = "";
      this.searchObj.Segment = "";
      this.searchObj.SubSegment = "";
      this.searchObj.InsightNo = "";
      this.searchObj.Lens = "";
      this.searchObj.InsightDetail = "";
      this.GetInsightData();
    },
    onClickLike(item) {
      let logLikeModel = {
        DocumentNo: item.InsightNo,
        LikeUser: this.userprofile.Username,
        DocumentType: "Insight",
      };

      if (item.ILike) {
        axios
          .post(URLLogLikeDelete, logLikeModel)
          .then((response) => {
            item.ILike = !item.ILike;
            this.$_toast_Noti("success", "success", response.data);
          })
          .catch((error) => {
            this.$_toast_Noti("error", "error", error);
          });
      } else {
        axios
          .put(URLLogLikeInsert, logLikeModel)
          .then((response) => {
            item.ILike = !item.ILike;
            this.$_toast_Noti("success", "success", response.data);
          })
          .catch((error) => {
            this.$_toast_Noti("error", "error", error);
          });
      }
    },
  },
  mounted() {
    this.GetSegment();
    this.GetSubSegment();
    this.GetLens();
    this.GetIndustry();
    this.GetBu();
    this.GetInsightData();
  },
};
</script>
